// If you don't want to use TypeScript you can delete this file!
import React from 'react';
import RegisterForm from '../components/register/register';
import Seo from '../components/seo';

import '../css/register/index.css';
import { MetaData } from '../utils/globals';

const Register: React.FC<{}> = ({ }) => (
    <>

        {/* SEO Start */}
        <Seo
            title={MetaData.HOME.META.title}
            description={MetaData.HOME.META.description}
            keywords={MetaData.HOME.META.keywords}
            author={MetaData.HOME.META.author}
            lang={MetaData.HOME.META.lang}
            meta={MetaData.HOME.META.meta}
            image={MetaData.HOME.META.image}
            portada={MetaData.HOME.META.portada}
        />
        {/* SEO End */}

        {/* Register Form Start */}
        <div
            className="border-none wh-100">
            <RegisterForm />
        </div>
        {/* Register Form End */}

    </>
);

export default Register;
