import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useState, useEffect, useRef } from "react"
import { configEnv } from "../../config"
import { registerService } from "../../services/register.services"
import Recaptcha from 'react-google-recaptcha';
import { STORE_TYPES } from "../../utils/storeTypes"

const CAPTCHA_KEY = "6LeyB9saAAAAALscNBLXMzr5LnqDDEEsfhH2jkLD";

const numberFormat = (num: string) => {
  const regex = /^[3]{1}[0-2]{1}[0-9]{1}[0-9]{7}$/
  return regex.test(num)
}

export const query = graphql`
  query {
    RegisterImage: file(relativePath: { eq: "register/register-image.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    MercabotLogo: file(relativePath: { eq: "home/logo-estori.png" }) {
      childImageSharp {
        fixed(width: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Register = () => {
  const [dataStore, setDataStore] = useState({
    email: "",
    firstName: "",
    lastName: "",
    nameCompany: "",
    password: "",
    phone: "",
    code: "",
    storetypeId: ""
  })

  useEffect(() => {
    let query = location.search
    const params = new URLSearchParams(query)
    if (query && query.includes("code")) {
      query = params.get("code")?.toUpperCase() || ""
      setDataStore({ ...dataStore, code: query })
    }
  }, [])

  const data = useStaticQuery(query)

  const [errors, setErrors] = useState("")
  const [googleToken, setGoogleToken] = useState<string | null>("");

  const storeIdParam =
    typeof window !== "undefined" && window.sessionStorage.getItem("storeId")
  const [storeId, setStoreId] = useState(storeIdParam || "");

  const recaptchaRef = useRef<any>();

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    if(!googleToken) {
      return setErrors("*Marque la casilla de verificación*");
    } 
    const validatePhone = numberFormat(dataStore.phone)
    if (validatePhone) {
      // const searchParams = new URLSearchParams(window.location.search);
      // const storeIdParam = searchParams.get('storeId') || '';
      setErrors("")
      const numberPhone = Number(`57${dataStore.phone}`)
      const dataForm = {
        ...dataStore,
        phone: numberPhone,
        storeId: storeId,
      }

      const { data } = await registerService.MerchantsExists(dataStore.email)
      if (data) {
        setErrors(
          `*Lo sentimos.<br/> Este correo electrónico ya se encuentra asociado a una tienda. Si no recuerdas tu usuario y contraseña, por favor contáctanos.`
        )
        return
      }

      if (dataStore.code) {
        const agent = await registerService.getAgentByCode(dataStore.code)
        if (agent.error) {
          setErrors(`*Código agente no valido.`)
          return
        }
        dataForm.code = agent._id
      }
     
      const response = await registerService.newStore(dataForm)
      if (response.status === 201) {
        window.location.href = `${configEnv.LOGIN}`
      } else {
        dataStore.code = ""
        response.error
          ? setErrors(response.error)
          : setErrors("*Ha ocurrido un error, intente de nuevo.")
      }
    } else {
      setErrors("*Escriba un número de celular válido.")
    }
  }

  const notHasSpecialCharactersAndWhiteSpace = (value: string) => {
    const reg = /^[a-z0-9]+$/g
    return reg.test(value)
  }

  const validateStoreId = async (storeId: string) => {
    try {
      if (notHasSpecialCharactersAndWhiteSpace(storeId)) {
        const response = await registerService.getAvailableStoreId(storeId)
        if (response.data) {
          setErrors(
            "*El nombre de la tienda ya se encuenta en uso, intente de nuevo"
          )
        } else {
          setErrors("")
        }
      } else {
        setErrors("*Solo se permiten letras en minúscula y números")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="row form-container">
      <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12 form-register align-items-center">
        <div className="logo-content">
          <Img
            loading="eager"
            fixed={data.MercabotLogo.childImageSharp.fixed}
            alt="Logo Estori"
          />
        </div>

        <h2>Obtén 15 días gratis</h2>
        <form className="form-content" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="InputStoreId">
              Nombre del dominio
              <span className="tool expand" data-title="Campo obligatorio">
                *
              </span>
            </label>
            <input
              type="text"
              value={storeId}
              onChange={e => setStoreId(e.target.value)}
              onBlur={e => validateStoreId(e.target.value)}
              className="form-control"
              required={true}
              id="InputStoreId"
            />
          </div>
          <div className="form-group">
            <label htmlFor="InputNameStore">
              Nombre de la empresa
              <span className="tool expand" data-title="Campo obligatorio">
                *
              </span>
            </label>
            <input
              type="text"
              value={dataStore.nameCompany}
              onChange={e =>
                setDataStore({ ...dataStore, nameCompany: e.target.value })
              }
              className="form-control"
              required={true}
              id="InputNameStore"
            />
          </div>
          <div className="form-group">
            <label htmlFor="InputFullName">
              Nombre
              <span className="tool expand" data-title="Campo obligatorio">
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              value={dataStore.firstName}
              onChange={e =>
                setDataStore({ ...dataStore, firstName: e.target.value })
              }
              required={true}
              id="InputFullName"
            />
          </div>

          <div className="form-group">
            <label htmlFor="InputLastName">
              Apellido
              <span className="tool expand" data-title="Campo obligatorio">
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              value={dataStore.lastName}
              onChange={e =>
                setDataStore({ ...dataStore, lastName: e.target.value })
              }
              required={true}
              id="InputLastName"
            />
          </div>

          <div className="form-group">
            <label htmlFor="InputPhone">
              Teléfono
              <span className="tool expand" data-title="Campo obligatorio">
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              value={dataStore.phone}
              onChange={e =>
                setDataStore({ ...dataStore, phone: e.target.value })
              }
              required={true}
              id="InputPhone"
            />
          </div>
          <div className="form-group">
            <label htmlFor="InputEmail">
              Correo electrónico
              <span className="tool expand" data-title="Campo obligatorio">
                *
              </span>
            </label>
            <input
              type="email"
              className="form-control"
              id="InputEmail"
              value={dataStore.email}
              onChange={e =>
                setDataStore({ ...dataStore, email: e.target.value.toLowerCase() })
              }
              required={true}
              aria-describedby="emailHelp"
            />
          </div>
          <div className="form-group">
            <label htmlFor="InputPassword">
              Contraseña
              <span className="tool expand" data-title="Campo obligatorio">
                *
              </span>
            </label>
            <input
              type="password"
              className="form-control"
              required={true}
              value={dataStore.password}
              onChange={e =>
                setDataStore({ ...dataStore, password: e.target.value })
              }
              id="InputPassword"
            />
          </div>
          <div className="form-group">
            <label htmlFor="SelectStoreType">
              Tipo de tienda
              <span className="tool expand" data-title="Campo obligatorio">
                *
              </span>
            </label>
            <select required={true} id="SelectStoreType" className="form-control form-control-sm" value={dataStore.storetypeId} onChange={({target: {value}}) => setDataStore((d) => ({...d, storetypeId: value}))}>
              <option value="" className="text-muted">Selecciona Opción</option>
              {STORE_TYPES.map(({value, label}) => <option key={`${value}-${Date.now()}`} value={value}>{label}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="InputCode">
              <span
                className="tool expand"
                data-title="Código referido o promocional"
              >
                Código
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              value={dataStore.code}
              maxLength={6}
              onChange={e =>
                setDataStore({
                  ...dataStore,
                  code: e.target.value.toUpperCase(),
                })
              }
              id="InputCode"
              autoComplete="off"
            />
          </div>
          

          <div className="form-group" />

          {errors.length !== 0 && (
            <small
              id="phoneError"
              className="form-text text-warning"
              dangerouslySetInnerHTML={{ __html: errors }}
            ></small>
          )}
          <div className="d-flex flex-column justify-content-center">
            <Recaptcha
              ref={recaptchaRef}
              sitekey={CAPTCHA_KEY}
              size="normal"
              onChange={(token) => setGoogleToken(token)}
              //id="recaptcha-google"
            />
            <button className="btn btn-warning my-2" type="submit" aria-label="">
              Crear cuenta
            </button>
          </div>
        </form>
        <p className="sign-in-text">
          ¿Ya tienes una cuenta? <a href={configEnv.LOGIN}>Inicia sesión</a>
        </p>
        <p className="terms-and-conditions">
          Al crear la cuenta asumimos que estás de acuerdo con la{" "}
          <a>política de privacidad</a> y los <a>términos y condiciones</a> del
          servicio de Estori
        </p>
      </div>
      <div className="col-lg-7 col-md-0 col-sm-0 col-xs-12 col-0 border-none p-0 img-register" />
    </div>
  )
}

export default Register
