export const STORE_TYPES = [
    {
        label: "Mercado",
        value: "STRCT001"
    },
    {
        label: "Farmacia",
        value: "STRCT002"
    },
    {
        label: "Licores",
        value: "STRCT003"
    },
    {
        label: "Tecnología",
        value: "STRCT004"
    },
    {
        label: "Moda",
        value: "STRCT005"
    },
    {
        label: "Floristería",
        value: "STRCT006"
    },
    {
        label: "Belleza",
        value: "STRCT007"
    },
    {
        label: "Hogar",
        value: "STRCT008"
    },
    {
        label: "Deportes",
        value: "STRCT009"
    },
    {
        label: "Juguetería",
        value: "STRCT010"
    },
    {
        label: "Mascotas",
        value: "STRCT011"
    },
    {
        label: "Librería",
        value: "STRCT012"
    },
    {
        label: "Otra",
        value: "OTHER"
    }
]